import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRouterMap = [

  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/loginView')
  }

]

export default new Router({
  routes: constantRouterMap
})

export const asyncRouterMap = [
  {
    path: '/layout',
    component: () => import('@/layout/homeView'),
    children: [
      {
        path: '/addMember',
        name: 'addMember',
        hidden: true,
        meta: {
          title: '编辑用户'
        },
        component: () => import('@/components/manage/addMember')
      },
      {
        path: '/addNews',
        name: 'addNews',
        hidden: true,
        meta: {
          title: '社区动态编辑'
        },
        component: () => import('@/components/manage/addNews')
      },
      {
        path: '/addFamily',
        name: 'addFamily',
        hidden: true,
        meta: {
          title: '社区服务编辑'
        },
        component: () => import('@/components/manage/addFamily')
      },
      {
        path: '/deptSelect',
        name: 'deptSelect',
        hidden: true,
        meta: {
          title: '编辑疫苗'
        },
        component: () => import('@/components/manage/deptSelect')
      },
      {
        path: '/addGood',
        name: 'addGood',
        hidden: true,
        meta: {
          title: '编辑商品'
        },
        component: () => import('@/components/goods/addGood.vue')
      },
    ]
  }
]

