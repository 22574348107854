/*
 * @Author: biqianqian
 * @Date: 2022-12-23 10:12:11
 * @LastEditors: biqianqian
 * @LastEditTime: 2022-12-23 10:12:23
 * @filePath: Do not edit
 */
import Vue from "vue";
/**
 * @description: 根据id取出name
 * @param {type} value - id
 * @param {Object[]} arr - 数组
 * @return {type}
 */
Vue.filter("findNameById", (value, arr) => {
  const result = arr.find(item => item.id == value);
  return result !== undefined ? result.name : "";
});