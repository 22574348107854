<!--
 * @Author: biqianqian
 * @Date: 2022-12-19 16:34:10
 * @LastEditors: biqianqian
 * @LastEditTime: 2022-12-19 17:05:10
 * @filePath: Do not edit
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  // created() {
  //   if (sessionStorage.getItem('menu')) {
  //     console.log(123445)
  //     let menu = JSON.parse(sessionStorage.getItem('menu'))
  //     this.$router.addRoutes(menu)
  //    // this.$router.options.routes.push(menu)
  //       this.$store.dispatch('addRoutes',menu)
  //   }

  //   },
  // computed: {
  //     getRoutes() {
  //         return this.$store.state.addRouters
  //     }
  // },
  //     computed: {
  //     getRoutes() {
  //         return this.$store.state.addRouters
  //     }
  // },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

body {
  font-size: 12px;
  color: #333;
  background: #fff;
}

.page {
  text-align: center;
  margin-top: 20px;
}

.padd {
  background: #fff;
  padding: 1%;
}

.el-breadcrumb {
  padding-top: 10px;
}

.el-divider--horizontal {
  margin-top: 20px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
