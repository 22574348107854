/*
 * @Author: biqianqian
 * @Date: 2022-12-19 17:09:33
 * @LastEditors: biqianqian
 * @LastEditTime: 2023-01-09 14:08:15
 * @filePath: Do not edit
 */
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import router from '@/router'

import Menufilter from './menufilter'; /* 用来处理后台传过来的component字段 */

const state = {
   addRoutes: [],
   routes: [],
   // 发布来源
   sourceArr: [
      { id: 1, name: "小程序" },
      { id: 2, name: "后台" }
   ],
   // 审核状态
   reviewArr: [
      { id: 0, name: "已通过" },
      { id: 1, name: "待审核" },
      { id: 2, name: "未通过" }
   ],
   // 推荐状态
   recommendArr: [
      { id: 0, name: "未推荐" },
      { id: 1, name: "已推荐" }
   ],
   topStatusArr: [
      { id: 0, name: "未置顶" },
      { id: 1, name: "已置顶" }
   ],
   homeStatusArr: [
      { id: 0, name: "未上" },
      { id: 1, name: "已上" }
   ]
}
const mutations = {
 ADD_ROUTES(state, addRouter) {
    console.log(addRouter)
   let routes = []
   Menufilter(routes,addRouter);
   router.addRoutes(routes);
   state.addRoutes = routes[0].children
   // if (JSON.stringify(state.addRoutes).indexOf('userCount') != -1) {
   //    router.push('/userCount');
   // } else {
   //    let path = addrouter[0].children[0].path
   //    router.push(path);
   // }
   let path = addRouter[0].children[0].path
   router.push(path);
 },
 DEL_ROUTES(state) {
    state.addRoutes = []
 }
};

const actions = {
 add_Routes({commit}, addrouter) {
   commit('ADD_ROUTES', addrouter);
 },
 del_Routes({commit}) {
    commit('DEL_ROUTES')
 }
};

const getters = {
   addRouters: (state) => {
       return state.addRoutes
   },
}



export default new Vuex.Store({
   state,
    mutations,
    actions,
    getters
})